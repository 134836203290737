<template>
  <q-page class="q-pa-sm">
    <div class="col-12">
      <q-item>
        <q-select
          v-model="entityId"
          :option-label="value => organizationsLabelMap[value]"
          :options="organizationsFiltered"
          class="full-width"
          clearable
          dense
          input-debounce="0"
          label="Текущая организация"
          outlined
          use-input
          @filter="filterOrganizations"
        />
      </q-item>
    </div>

    <div v-if="entityId">
      <organization-info v-model="entity"/>
      <organization-employee v-model="entity" class="q-my-md"/>
      <organization-offers v-model="entity" class="q-my-md"/>
    </div>
  </q-page>
</template>

<script>
import { OrganizationApi } from '@/api/apis/organization-api.ts';
import { UserApi } from '@/api/apis/user-api.ts';
import OrganizationEmployee from '@/components/organizations/employee';
import OrganizationInfo from '@/components/organizations/info';
import OrganizationOffers from '@/components/organizations/offers';
import { debounce } from 'quasar';
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    OrganizationInfo,
    OrganizationEmployee,
    OrganizationOffers,
  },

  setup() {
    const store = useStore();

    const entityId = ref(store.state.user.scope);
    const entity = ref({
      name: '',
      description: '',
      logo_url: '',
      account_no: 0,
      external: [],
    });

    const update = debounce(() => {
      store.commit('setScope', entityId.value);

      if (entityId.value) {
        new OrganizationApi().organizationControllerGetOrganization(entityId.value)
          .then(({ data }) => {
            entity.value = data;
          })
          .catch(() => entityId.value = 0);
      }
    }, 100);

    onMounted(update);
    watch(() => entityId.value, update);

    const organizationsFiltered = ref([]);
    const organizations = ref([]);
    const organizationsLabelMap = ref({});

    new UserApi().personControllerGetPersonOrganizations().then(({ data }) => {
      organizations.value = data.map((item) => item.account_no);

      data.forEach((item) => {
        organizationsLabelMap.value[item.account_no] = item.name;
      });

      if (!entityId.value) {
        entityId.value = organizations.value[0] || 0;
      }
    });

    return {
      roles: store.getters.rolesSet,
      entityId,
      entity,
      organizations,
      organizationsFiltered,
      organizationsLabelMap,
      filterOrganizations(val, update) {
        if (val === '') {
          update(() => {
            organizationsFiltered.value = organizations.value;
          });
          return;
        }

        update(() => {
          const needle = val.toLowerCase();
          organizationsFiltered.value = [];
          for (const id in organizationsLabelMap.value) {
            if (organizationsLabelMap.value[id].toLowerCase().has(needle)) {
              organizationsFiltered.value.push(id);
            }
          }
        });
      },
    };
  },
});
</script>

<style>

</style>
